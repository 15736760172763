const svTranslations = {
  select_for_referral: 'Remittera hit',
  filter: 'Filtrera',
  active: 'Aktiv',
  inactive: 'Inaktiv',
  value_missing: 'Värde saknas',
  field_cannot_be_empty: 'Fältet får ej vara tomt',
  fetch_address_info: 'Hämta adressinformation',
  information_found: 'Hittad information',
  address: 'Adress',
  latitude: 'Latitud',
  longitude: 'Longitud',
  is_information_correct: 'Verkar informationen stämma?',
  save_coordinates: 'Spara koordinater',
  save_and_move_cursor_sideways: 'Spara och förflytta markör i sidled',
  could_not_find_any_address_info:
    'Kunde inte hitta några träffar. Se till att adressen är korrekt inmatad.',
  something_went_wrong: 'Något gick fel. Var vänlig försök igen senare.',
  address_missing: 'Kliniken saknar adress.',
  cancel: 'Avbryt',
  type_of_animal: 'Djurslag',
  choose_type_of_animal: 'Välj djurslag',
  clinic_accepts: 'Kliniken tar emot',
  add_animal_type: 'Lägg till djurslag',
  add: 'Lägg till',
  contact_information: 'Kontaktinformation',
  name: 'Namn',
  city: 'Stad',
  municipality: 'Län',
  country: 'Land',
  email: 'E-postadress',
  phone: 'Telefonnummer',
  website: 'Hemsida',
  status: 'Status',
  '2288-pilot': '2288-pilot',
  size_general_info: 'Storlek & generell information',
  clinic_type: 'Kliniktyp',
  number_of_vets: 'Antal veterinärer',
  small_animals: 'Smådjur',
  horse: 'Häst',
  general_info: 'Generell information',
  information_horse_service: 'Information hästtjänst',
  referral: 'Remittering',
  email_referral: 'E-postadress för remisser',
  phone_referral: 'Telefonnummer för remisser',
  referral_information: 'Remissinformation',
  location_info: 'Platsinformation',
  coordinates: 'Koordinater',
  services: 'Tjänster',
  add_specific_service: 'Lägg till specifik tjänst',
  animal_group: 'Djurkategori',
  service_category: 'Tjänstekategori',
  save_services: 'Spara tjänster',
  availability: 'Tillgänglighet',
  emergency_care_options: 'Akutsjukvård (behandlingsmöjligheter)',
  emergency_care: 'Akutsjukvård',
  on_call_options: 'Jourberedskap',
  inpatient_care_small_animals: 'Stationärvård (smådjur)',
  inpatient_care_horses: 'Stationärvård (hästar)',
  yes: 'Ja',
  no: 'Nej',
  opening_hours: 'Öppettider',
  opens: 'Öppnar',
  closes: 'Stänger',
  closed: 'Stängt',
  save_opening_hours: 'Spara öppettider',
  opening_hours_saved: 'Öppettider sparade',
  other_opening_hours: 'Övriga öppettider',
  other_opening_hours_placeholder: 'Ex. lunchstängt',
  opening_hours_error:
    'Något gick fel. Vänligen kontrollera att stängningstid är efter öppningstid.',
  opening_hours_missing_error:
    'Både öppning och stängning måste fyllas i (om ej stängt)',
  selected_time_is_in_wrong_format: 'Angiven tid har fel format',
  copy_contact_info: 'Kopiera kontaktuppgifter',
  copy_clinic_name: 'Kopiera klinikens namn',
  copied_to_clipboard: 'Kopierat!',
  phone_number: 'Telefonnummer',
  public: 'Publikt',
  referral_hidden: 'Remiss (hemligt)',
  inpatient_care: 'Stationärvård',
  general_information: 'Generell information',
  horses: 'Hästar',
  horses_field: 'Hästar (fält)',
  animal_species: 'Djurslag',
  agricultural_animals: 'Lantbruksdjur',
  referral_info: 'Remissinformation',
  search: 'Sök',
  no_search_results: 'Inga sökresultat',
  mon: 'Mån',
  tue: 'Tis',
  wed: 'Ons',
  thu: 'Tors',
  fri: 'Fre',
  sat: 'Lör',
  sun: 'Sön',
  monday: 'Måndag',
  tuesday: 'Tisdag',
  wednesday: 'Onsdag',
  thursday: 'Torsdag',
  friday: 'Fredag',
  saturday: 'Lördag',
  sunday: 'Söndag',
  currently_open: 'Öppna just nu',
  clinics: 'Kliniker',
  active_areas: 'Aktiva områden',
  distances: 'Sträckor',
  save: 'Spara',
};

export default svTranslations;
