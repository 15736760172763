import OT from '@opentok/client';
import { AppointmentApi } from '@/api';
import { SV_COUNTRY_ID, US_COUNTRY_ID } from '@/config/countries';

export default {
  state: {
    session: null,
    stream: null,

    subscriber: null,
    publisher: null,

    token: '',
    sessionID: '',
    apiKey: process.env.VUE_APP_OPENTOK_KEY,
    apiKeySv: process.env.VUE_APP_OPENTOK_KEY_SV,
    apiKeyUs: process.env.VUE_APP_OPENTOK_KEY_US,

    active: false,
    activating: false,

    vetOnline: false,
    vetOffline: true,
    animalOwnerOnline: false,

    audioDevices: [],
    videoDevices: [],

    /** Settings */
    showPublisher: false,
    micEnabled: true,
    videoEnabled: true,

    animalOwnerAudioScore: null,
    vetAudioScore: null,
    animalOwnerVideoScore: null,
    vetVideoScore: null,

    zoomed: false,
    message: '',
  },

  getters: {
    getToken: state => state.token,
    getSessionID: state => state.sessionID,
    getApiKey: (state, _, rootState) => {
      switch (rootState.user.user.country_id) {
        case SV_COUNTRY_ID:
          return state.apiKeySv;
        case US_COUNTRY_ID:
          // TODO enable on M-day
          // return state.apiKeyUs;
          return state.apiKey;
        default:
          return state.apiKey;
      }
    },
    getActiveOrActivating: state => state.active || state.activating,
    getActivating: state => state.activating,
    getAnimalOwnerAudioScore: state => state.animalOwnerAudioScore,
    getAnimalOwnerVideoScore: state => state.animalOwnerVideoScore,
    getVetAudioScore: state => state.vetAudioScore,
    getVetVideoScore: state => state.vetVideoScore,
  },

  mutations: {
    resetSessionState(state) {
      state.active = false;
      state.session = null;
      state.stream = null;
      state.showPublisher = false;
      state.micEnabled = true;
      state.videoEnabled = true;
      state.subscriber = null;
      state.publisher = null;
    },
    toggleShowPublisher(state, payload) {
      state.showPublisher = payload;
    },
    toggleMicEnabled(state, payload) {
      state.micEnabled = payload;
    },
    toggleVideoEnabled(state, payload) {
      state.videoEnabled = payload;
    },
    setStream(state, payload) {
      state.stream = payload;
    },
    setSubscriber(state, subscriber) {
      state.subscriber = subscriber;
    },
    setPublisher(state, publisher) {
      state.publisher = publisher;
    },
    setToken: (state, payload) => {
      state.token = payload;
    },
    setSessionID: (state, payload) => {
      state.sessionID = payload;
    },
    setSession: (state, payload) => {
      state.session = payload;
    },
    setVetOnline: (state, payload) => {
      state.vetOnline = payload;
    },
    setVetOffline: (state, payload) => {
      state.vetOffline = payload;
    },
    setAnimalOwnerOnline: (state, payload) => {
      state.animalOwnerOnline = payload;
    },
    setActive: (state, payload) => {
      state.active = payload;
    },
    setActivating: (state, payload) => {
      state.activating = payload;
    },
    setAudioDevices: (state, payload) => {
      state.audioDevices = payload;
    },
    setVideoDevices: (state, payload) => {
      state.videoDevices = payload;
    },
    setAnimalOwnerAudioScore: (state, payload) => {
      state.animalOwnerAudioScore = payload;
    },
    setAnimalOwnerVideoScore: (state, payload) => {
      state.animalOwnerVideoScore = payload;
    },
    setVetAudioScore: (state, payload) => {
      state.vetAudioScore = payload;
    },
    setVetVideoScore: (state, payload) => {
      state.vetVideoScore = payload;
    },
    setZoomed: (state, payload) => {
      state.zoomed = payload;
    },
    setMessage: (state, payload) => (state.message = payload),
  },

  actions: {
    async initSession({ commit, state, getters }) {
      const apiKey = getters.getApiKey;
      const session = await OT.initSession(apiKey, state.sessionID);
      commit('setSession', session);
      return session;
    },
    async connectSession({ dispatch, state }) {
      if (!state.session) {
        await dispatch('initSession');
      }
      await state.session.connect(state.token);
      return true;
    },

    setActive: ({ commit }, payload) => {
      commit('setActive', payload);
    },

    setActivating: ({ commit }, payload) => {
      commit('setActivating', payload);
    },

    stopPublishing({ state }) {
      if (state.publisher) {
        state.publisher.publishVideo(false);
        state.publisher.publishAudio(false);
        state.session.unpublish(state.publisher);
        state.publisher.destroy();
        state.session.disconnect();
        state.publisher = null;
      }
    },

    async setVetOnline({ commit }, payload) {
      await AppointmentApi.setVetOnline(payload);
      commit('setVetOnline', true);
      commit('setVetOffline', false);
      return true;
    },

    async setVetOffline({ commit }, payload) {
      await AppointmentApi.setVetOffline(payload);
      commit('setVetOffline', true);
      commit('setVetOnline', false);
      return true;
    },

    async callNotification(_, { appointmentId }) {
      return await AppointmentApi.callNotification(appointmentId);
    },

    async hangUp(_, { appointmentId }) {
      return await AppointmentApi.hangUp(appointmentId);
    },

    toggleMicEnabled({ commit, state }, payload) {
      commit('toggleMicEnabled', payload);
      if (state.publisher) {
        state.publisher.publishAudio(payload);
      }
    },
    toggleVideoEnabled({ commit, state }, payload) {
      commit('toggleVideoEnabled', payload);
      if (state.publisher) {
        state.publisher.publishVideo(payload);
      }
    },

    setAnimalOwnerOnline({ commit }, payload) {
      commit('setAnimalOwnerOnline', payload);
    },

    getArchiveStream(_, payload) {
      return AppointmentApi.getVideoStream(payload)
        .then(res => {
          return res;
        })
        .catch(e => {
          console.error(e);
          return e;
        });
    },

    getDevices({ commit }) {
      return new Promise((resolve, reject) => {
        OT.getDevices((error, devices) => {
          if (error) {
            console.error(error);
            reject(error);
          }

          const audioInputDevices = [];
          const videoInputDevices = [];

          devices.forEach(device => {
            switch (device.kind) {
              case 'audioInput':
                audioInputDevices.push(device);
                break;
              case 'videoInput':
                videoInputDevices.push(device);
                break;
              default:
            }
          });

          commit('setAudioDevices', audioInputDevices);
          commit('setVideoDevices', videoInputDevices);
          resolve('Input devices set');
        });
      });
    },

    setAnimalOwnerAudioScore: ({ commit }, payload) => {
      commit('setAnimalOwnerAudioScore', payload);
    },

    setAnimalOwnerVideoScore: ({ commit }, payload) => {
      commit('setAnimalOwnerVideoScore', payload);
    },

    setVetAudioScore: ({ commit }, payload) => {
      commit('setVetAudioScore', payload);
    },

    setVetVideoScore: ({ commit }, payload) => {
      commit('setVetVideoScore', payload);
    },
  },
};
