import { setProperty } from './utils';

export const userInitializedEvent = async data =>
  setProperty({
    event: 'user_initialized',
    userID: data.user.id,
    user: cleanUserProperties(data),
  });

export const userDetailsInitializedEvent = async data =>
  setProperty({
    event: 'user_details_initialized',
    user: cleanUserDetailsProperties(data),
  });

const cleanCountryObjProps = country => ({
  id: country.id,
  iso_3166_2: country.iso_3166_2,
  localization: country.localization,
  name: country.name,
});

const cleanUserObjProps = user => ({
  id: user.id,
  first_name: user.first_name,
  last_name: user.last_name,
  display_name: user.display_name,
  email: user.email,
  mobile_phone: user.mobile_phone,
  country_id: user.country_id,
  country_code_id: user.country_code_id,
});

const cleanRoleObjProps = roles =>
  roles.map(role => ({
    id: role.id,
    name: role.name,
  }));

const cleanUserProperties = data => ({
  ...cleanUserObjProps(data.user),
  country: cleanCountryObjProps(data.country),
  roles: cleanRoleObjProps(data.roles),
  timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
});

const cleanUserServiceObjProps = services =>
  services.map(service => ({
    id: service.service_id,
    user_service_id: service.id,
    slot_id: service.slot_id,
    currency_id: service.currency_id,
    title: service.title,
    price_normal: service.price_normal,
    price_inconvenient: service.price_inconvenient,
    price_holiday: service.price_holiday,
  }));

const cleanUsStateVetLicences = licences =>
  licences.map(licence => ({
    id: licence.id,
    state: licence.state,
  }));

const cleanLanguageObjProp = languages =>
  languages.map(lang => ({
    id: lang.id,
    language_code: lang.language_code,
    name: lang.name,
  }));

const cleanAnimalTypeObjProps = animalTypes =>
  animalTypes.map(animalType => ({
    id: animalType.id,
    key: animalType.key,
    name: animalType.name,
  }));

const cleanUserDetailsProperties = data => ({
  ...window.dataLayer.user,
  country_code: data.user.country_code,
  status: data.user.status,
  us_state_id: data.user.us_state_id,
  us_state_vet_licences: cleanUsStateVetLicences(data.usStateVetLicences),
  languages: cleanLanguageObjProp(data.languages),
  services: cleanUserServiceObjProps(data.services),
  animal_types: cleanAnimalTypeObjProps(data.animalTypes),
});
