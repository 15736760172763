import {
  fetchAndActivate,
  getRemoteConfig,
  getValue,
  RemoteConfig,
} from 'firebase/remote-config';

export interface RemoteConfigInterface {
  title: string;
  message: string;
  linkText: string;
  link: string;
  dismissible: boolean;
  status: string;
}

export default class RemoteConfigService {
  remoteConfig: RemoteConfig;

  constructor({ app }) {
    this.remoteConfig = getRemoteConfig(app);
    this.remoteConfig.settings.minimumFetchIntervalMillis = 3600000;
  }

  getValue(key: string): Promise<RemoteConfigInterface> {
    return fetchAndActivate(this.remoteConfig)
      .then(() => getValue(this.remoteConfig, key).asString())
      .then(config => {
        return config ? JSON.parse(config) : {};
      });
  }
}
