// eslint-disable-next-line import/no-cycle
import { AxiosPromise } from 'axios';
import { SV_COUNTRY_ID } from '@/config/countries';
import axios from '@/plugins/axios';

export default {
  getAll(payload): AxiosPromise {
    return axios.post('/api/insurancecompanies', payload);
  },
  getAllByCountryId(countryId: number, params = {}): AxiosPromise {
    const countryIdParam = countryId === SV_COUNTRY_ID ? '' : countryId;

    return axios.get(`/api/insurance/${countryIdParam}`, { params });
  },
  get(payload): AxiosPromise {
    return axios.get('/api/insurancecompany', {
      params: {
        id: payload,
      },
    });
  },
};
