/* eslint-disable import/no-cycle */
import { Ability } from '@casl/ability';
import { TYPE_KEY } from '@/utils/ability-type';
import { AuthApi } from '@/api';
import updateAbility from '@/plugins/casl/ability';
import router from '@/router';
import { setCookie } from '@/utils/cookie-utils';
import { FIRSTVET_TOKEN_COOKIE_DOMAIN } from '@/constants';

const ADMIN_ROLE_ID = 1;
const VET_ROLE_ID = 3;

// ===
// Private helpers
// ===

function getSavedState(key) {
  return localStorage.getItem(key);
}

function saveState(key, state) {
  localStorage.setItem(key, state);
}

function removeState(key) {
  localStorage.removeItem(key);
}

function setDefaultAuthHeaders(state) {
  axios.defaults.headers.common.Authorization = state.token
    ? `Bearer ${state.token}`
    : '';
  axios.defaults.headers.common['Accept-Language'] = state.locale;
}

export default {
  state: {
    token: getSavedState('token') || '',
    locale: getSavedState('locale') || '',
    authenticated: false,
    roles: null,

    isDigitalClinicAdmin: false,
    permissions: [],
    use2fa: false,
  },

  getters: {
    getToken: state => state.token,
    isAuthenticated: state => state.authenticated,
    getRoles: state => state.roles,

    isAdmin: state => {
      if (state.roles) {
        return !!state.roles.find(
          role => (role.id || role.role_id) === ADMIN_ROLE_ID
        );
      }
      return false;
    },
    isVet: state => {
      if (state.roles) {
        return !!state.roles.find(role => role.id === VET_ROLE_ID);
      }
      return false;
    },
    isDigitalClinicUser: state => {
      if (state.roles) {
        return !!state.roles.find(role => {
          if (role.name) {
            return role.name.includes('DigitalClinic');
          }
          return false;
        });
      }
      return false;
    },
    isReferralCoordinator: state => {
      if (state.roles) {
        return !!state.roles.find(role => {
          if (role.name) {
            return role.name.includes('ReferralAgent');
          }
          return false;
        });
      }
      return false;
    },
    isDigitalClinicAdmin: state => {
      return state.isDigitalClinicAdmin;
    },
    canManageQuestions: state => {
      if (state.roles) {
        return !!state.roles.find(role => role.name === 'QuestionManager');
      }
      return false;
    },

    ability() {
      return new Ability([], {
        subjectName(subject) {
          return !subject || typeof subject === 'string'
            ? subject
            : subject[TYPE_KEY];
        },
      });
    },
  },

  mutations: {
    setToken: (state, payload) => {
      state.token = payload;
    },
    setAuth: (state, payload) => {
      state.authenticated = payload;
    },
    setRoles: (state, payload) => {
      state.roles = payload;
    },
    setLocale: (state, payload) => {
      state.locale = payload;
    },

    setIsDigitalClinicAdmin: (state, payload) => {
      state.isDigitalClinicAdmin = payload;
    },
    setPermissions: (state, claims) => {
      updateAbility(claims);
      if (claims && claims.permissions) {
        state.permissions = claims.permissions;
      }
    },
  },

  actions: {
    async loginTwoFactor(_, payload) {
      const res = await AuthApi.loginTwoFactor(payload);
      return res;
    },

    async submitCodeTwoFactor(_, { code, token }) {
      try {
        const res = await AuthApi.submitCodeTwoFactor(code, token);
        if (res.data.message === 'success') {
          return res.data;
        }
        return Promise.reject(new Error('Invalid code'));
      } catch (e) {
        return Promise.reject(e);
      }
    },

    async login({ commit }, payload) {
      try {
        const res = await AuthApi.login(payload);
        commit('setLocale', res.data.locale);
        saveState('locale', res.data.locale);
        return res;
      } catch (e) {
        commit('setAuth', false);
        return Promise.reject(e);
      }
    },

    logout({ commit, state }) {
      return new Promise(resolve => {
        setCookie({
          name: 'firstvetToken',
          value: '',
          domain: FIRSTVET_TOKEN_COOKIE_DOMAIN,
          maxAge: 0,
        });
        commit('setAuth', false);
        commit('setToken', '');
        commit('setRoles', null);
        commit('setPermissions', null);

        commit('user/setUser', null, { root: true });

        removeState('token');
        removeState('user');
        removeState('locale');
        removeState('platform');

        setDefaultAuthHeaders(state);
        resolve();
      });
    },

    resetPassword(_, payload) {
      return AuthApi.resetPassword(payload)
        .then(res => {
          console.log('pwrest', res);
        })
        .catch(e => {
          console.log(e);
        });
    },

    setAuthData({ commit, dispatch, state }, payload) {
      return new Promise((resolve, reject) => {
        try {
          commit('setToken', payload);
          saveState('token', state.token);
          saveState('locale', state.locale);
          setDefaultAuthHeaders(state);
          commit('setAuth', true);
          dispatch('user/fetchUser', {}, { root: true })
            .then(() => {
              resolve(true);
            })
            .catch(e => {
              reject(e);
            });
        } catch (e) {
          reject(e);
        }
      });
    },

    handleLoginRoutePush({ getters }) {
      if (getters.isAdmin) {
        router.push({ name: 'schedule-dashboard' });
        return;
      }
      if (getters.isReferralCoordinator) {
        router.push({ name: '2288-dashboard' });
        return;
      }
      router.push({ name: 'dashboard' });
    },
  },
};
