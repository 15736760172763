import Vue from 'vue';
import VueGtm from 'vue-gtm';
import router from '@/router';
import { __PROD__ } from '@/constants';

Vue.use(VueGtm, {
  id: __PROD__ ? 'GTM-T9FM39G' : 'GTM-KFM2TDC',
  vueRouter: router,
  enabled: true,
  debug: __PROD__ ? false : true,
});
