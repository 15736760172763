import { render, staticRenderFns } from "./BaseLabel.vue?vue&type=template&id=be9c0ca2&"
import script from "./BaseLabel.vue?vue&type=script&lang=js&"
export * from "./BaseLabel.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/firstvet/vetpanel/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('be9c0ca2')) {
      api.createRecord('be9c0ca2', component.options)
    } else {
      api.reload('be9c0ca2', component.options)
    }
    module.hot.accept("./BaseLabel.vue?vue&type=template&id=be9c0ca2&", function () {
      api.rerender('be9c0ca2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/UI/inputs/BaseLabel.vue"
export default component.exports