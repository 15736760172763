import RemoteConfigService from '@/api/modules/firebase/remote-config';
import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_APIKEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTHDOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASEURL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.VUE_APP_FIREBASE_APPID,
};

const app = initializeApp(firebaseConfig);

export default {
  remoteConfig: new RemoteConfigService({ app }),
};

export { RemoteConfigInterface } from '@/api/modules/firebase/remote-config';
