import { SV_COUNTRY_ID, UK_COUNTRY_ID } from '@/config/countries';

export default [
  {
    name: 'ecommerce',
    path: '/ecommerce/:country',
    beforeEnter(to) {
      if (to.params.country == UK_COUNTRY_ID) {
        location.href = 'https://shop.firstvet.com/uk';
      } else {
        location.href = 'https://shop.firstvet.com/sv';
      }
    },
  },
  {
    name: 'handbook',
    path: '/handbook/:country',
    beforeEnter(to) {
      if (to.params.country == SV_COUNTRY_ID) {
        location.href =
          'https://docs.google.com/document/d/19UzTS2sLME_qv3nNCldqjCNi1z-zoxSqHhDXiveF_0Y/edit#heading=h.978noky6ie1';
      }
    },
  },
];
