var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.errorMsg
        ? _c("p", { staticClass: "text-center text-red-800" }, [
            _vm._v(" " + _vm._s(_vm.errorMsg) + " "),
          ])
        : _vm._e(),
      _vm.legacyLogin
        ? _c(
            "div",
            [
              _c(
                "div",
                {
                  staticClass:
                    "mt-4 flex w-full flex-wrap justify-between border-b border-gray-200 px-8",
                },
                [
                  _c("div", { staticClass: "inline-flex space-x-4" }, [
                    _c(
                      "button",
                      {
                        staticClass:
                          "border-b-2 border-transparent px-3 py-2 hover:text-gray-900",
                        class: { "border-navy": _vm.loginMethod === "bankId" },
                        on: {
                          click: function ($event) {
                            _vm.loginMethod = "bankId"
                          },
                        },
                      },
                      [_vm._v(" BankID ")]
                    ),
                    _c(
                      "button",
                      {
                        staticClass:
                          "border-b-2 border-transparent px-3 py-2 hover:text-gray-900",
                        class: { "border-navy": _vm.loginMethod === "email" },
                        attrs: { "data-testid": "login-email" },
                        on: {
                          click: function ($event) {
                            _vm.loginMethod = "email"
                          },
                        },
                      },
                      [_vm._v(" Email ")]
                    ),
                  ]),
                ]
              ),
              _vm.loginMethod === "email"
                ? _c("email-password-login")
                : _vm._e(),
              _vm.loginMethod === "bankId"
                ? _c(
                    "form",
                    {
                      staticClass: "flex flex-wrap px-8 py-8",
                      attrs: { id: "bankIdLoginForm" },
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.submit.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _vm.message
                        ? _c(
                            "p",
                            {
                              staticClass: "mb-2 block",
                              class: { "text-red": _vm.uiState === "error" },
                            },
                            [_vm._v(" " + _vm._s(_vm.message) + " ")]
                          )
                        : _vm._e(),
                      _c("transition", { attrs: { name: "fade" } }, [
                        _vm.uiState === "idle" || _vm.uiState === "error"
                          ? _c(
                              "div",
                              { staticClass: "flex w-full flex-col space-y-2" },
                              [
                                _c("base-label", { attrs: { for: "email" } }, [
                                  _vm._v(" Sign in with BankID "),
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "flex w-full flex-col space-y-4",
                                  },
                                  [
                                    _c("base-input", {
                                      directives: [
                                        { name: "focus", rawName: "v-focus" },
                                      ],
                                      staticClass: "w-1/2",
                                      attrs: {
                                        id: "ssn",
                                        type: "tel",
                                        placeholder: "Enter your SSN",
                                        required: "",
                                      },
                                      model: {
                                        value: _vm.ssn,
                                        callback: function ($$v) {
                                          _vm.ssn = $$v
                                        },
                                        expression: "ssn",
                                      },
                                    }),
                                    _c(
                                      "base-button",
                                      {
                                        attrs: {
                                          color: "primary",
                                          disabled: !_vm.ssn,
                                        },
                                      },
                                      [
                                        _c("font-awesome-icon", {
                                          attrs: { icon: "sign-in-alt" },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.uiState === "loading"
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "w-full rounded bg-azure-25 p-4 shadow-sm",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "block" },
                                  [
                                    _c("div", [
                                      _c("span", [
                                        _vm._v(
                                          "Open the BankID app in your mobile phone"
                                        ),
                                      ]),
                                      _c(
                                        "span",
                                        { staticClass: "ml-2" },
                                        [
                                          _c("base-spinner", {
                                            staticClass: "text-xl",
                                          }),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _c(
                                      "base-button",
                                      {
                                        staticClass: "mt-4",
                                        attrs: {
                                          color: "cancel",
                                          type: "button",
                                        },
                                        on: { click: _vm.cancelAuth },
                                      },
                                      [_vm._v(" Cancel ")]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.uiState === "authorized"
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "w-full rounded bg-primary-lighter p-4 shadow-sm",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "flex items-center space-x-2",
                                  },
                                  [
                                    _c("fv-icon", {
                                      staticClass: "text-navy",
                                      attrs: { icon: "check-mark" },
                                    }),
                                    _c("span", [
                                      _vm._v("Authorized! Redirecting.."),
                                    ]),
                                    _c(
                                      "span",
                                      { staticClass: "ml-2" },
                                      [
                                        _c("base-spinner", {
                                          staticClass: "text-xl",
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _c(
            "base-button",
            {
              staticClass: "my-4 w-full",
              attrs: {
                color: "primary",
                to: _vm.firstvetAuthUrl,
                href: _vm.firstvetAuthUrl,
                "data-testid": "login-button",
              },
            },
            [_vm._v(" Logga in ")]
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }