<template>
  <div>
    <form
      :id="`${platform}LoginForm`"
      class="flex flex-col space-y-2 px-8 py-8"
      @submit.prevent="submit"
    >
      <p v-if="errorMsg" class="text-red-800">
        {{ errorMsg }}
      </p>
      <div class="flex flex-col space-y-2">
        <base-label for="email"> Email </base-label>
        <base-input
          id="email"
          v-model="email"
          v-focus
          data-testid="email-input"
          type="email"
          placeholder="Enter your email"
          required
        />
      </div>
      <div class="flex flex-col space-y-2">
        <base-label for="password"> Password </base-label>
        <base-input
          id="password"
          v-model="userPassword"
          type="password"
          data-testid="password-input"
          class=""
          placeholder="***********"
          required
        />
      </div>
      <div>
        <base-button
          id="loginButton"
          v-loading="loading"
          data-testid="login-button"
          color="primary"
          class="mt-2 w-full"
          :loading="loading"
          :disabled="loading"
        >
          Sign in
        </base-button>
      </div>
    </form>

    <div class="mb-6 w-full text-center">
      <text-button
        class="font-semibold"
        @click.prevent.native="forgotPassword = true"
      >
        Forgot your password?
      </text-button>
    </div>
    <base-modal
      :show="forgotPassword"
      :show-close-button="false"
      @close="forgotPassword = false"
    >
      <forgot-password-form :email="email" @cancel="forgotPassword = false" />
    </base-modal>
    <two-factor-modal
      :show="showTwoFactorModal"
      :phone-number="phoneNumber"
      :country-code="countryCode"
      :temp-token="tempToken"
      @close="showTwoFactorModal = false"
      @keyup.esc="showTwoFactorModal = false"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import BaseModal from '@/UI/modals/base-modal';
import ForgotPasswordForm from '@/components/auth/ForgotPasswordForm';
import TwoFactorModal from '@/components/auth/TwoFactorModal';
import { authMethods, authComputed } from '@/store/auth-helpers';

export default {
  components: {
    BaseModal,
    ForgotPasswordForm,
    TwoFactorModal,
  },

  data() {
    return {
      loading: false,

      email: '',
      userPassword: '',

      forgotPassword: false,

      showTwoFactorModal: false,

      phoneNumber: '',

      tempToken: '',
      countryCode: 3,

      authError: '',
      disabledButton: false,

      name: '',
      logo: '',
      logoError: false,
      errorMsg: '',
    };
  },

  computed: {
    ...authComputed,
    ...mapState(['platform']),
    ...mapState('country', ['countryCodes']),
    countryCodeList() {
      return this.countryCodes.map(code => {
        return {
          id: code.id,
          name: code.country,
          code: code.code,
        };
      });
    },
  },

  mounted() {
    if (!this.countryCodes.length) {
      this.fetchCountryCodes();
    }
  },

  methods: {
    ...authMethods,
    ...mapActions('country', ['fetchCountryCodes']),
    async submit() {
      this.loading = true;
      this.errorMsg = '';

      try {
        const { data } = await this.login({
          email: this.email,
          password: this.userPassword,
          os: navigator.platform,
          timezone: Intl
            ? Intl.DateTimeFormat().resolvedOptions().timeZone
            : null,
        });
        this.loading = false;
        const { mobile_phone: mobilePhone, country_code: countryCode } =
          data.user;

        if (!mobilePhone || !countryCode) {
          this.$notify({
            group: 'error',
            title: 'Error',
            text: `We could not find a ${
              !mobilePhone ? 'phone number' : 'country code'
            } linked to your account. Please contact support for further assistance.`,
          });

          return;
        }

        if (this.use2fa) {
          this.phoneNumber = mobilePhone;
          this.countryCode = countryCode.id;
          this.showTwoFactorModal = true;
          this.tempToken = data.token;
        } else {
          this.setAuthData(data.token);
          this.handleLoginRoutePush();
        }
      } catch (e) {
        this.errorMsg = e.message;
        this.loading = false;
      }
    },
  },
};
</script>
