<template>
  <div
    v-if="show"
    class="fixed inset-0 z-50 flex items-center justify-center px-4 pb-4"
    @keyup.esc="$emit('close')"
  >
    <div
      v-if="show"
      class="top fixed inset-0 z-20 transition-opacity ease-in-out"
    >
      <div class="absolute inset-0 bg-fv-black opacity-10" @click="close" />
    </div>
    <div
      v-if="show"
      :class="[width]"
      class="relative z-50 flex w-full transform flex-col justify-center rounded-lg bg-white shadow-xl transition-all"
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-headline"
    >
      <div class="flex flex-col overflow-hidden" style="max-height: 85vh">
        <div id="baseModalWrapper" tabindex="0" class="overflow-auto p-4">
          <slot />
        </div>
      </div>
      <div
        v-show="$slots.buttons"
        class="flex w-full justify-end space-x-2 rounded-b-lg border-t bg-beigeLighter px-4 py-2 sm:px-6"
      >
        <slot class="contents" name="buttons">
          <base-button
            v-if="showCloseButton"
            color="cancel"
            type="button"
            @click="close"
          >
            Close
          </base-button>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: true,
    },
    showCloseButton: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: 'default',
    },
  },

  computed: {
    width() {
      if (this.size === 'sm') {
        return 'max-w-md';
      }
      if (this.size === 'md') {
        return 'max-w-3xl';
      }
      if (this.size === 'lg') {
        return 'max-w-5xl';
      }
      if (this.size === 'xl') {
        return 'max-w-6xl';
      }
      return 'max-w-4xl';
    },
  },

  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>
