// eslint-disable-next-line import/no-cycle
import { AxiosPromise } from 'axios';
import axios from '@/plugins/axios';

export default {
  initBankId(payload: any): AxiosPromise {
    return axios.post('/api/bankid/sign', payload);
  },

  initDevBankId(payload: any): AxiosPromise {
    return axios.post('/api/auth/login/panel/dev-init', payload);
  },

  cancelBankId(orderRef: string): AxiosPromise {
    return axios.post('/api/bankid/cancel', { orderRef });
  },

  checkBankIdResponse(orderRef: string, attempt: number): AxiosPromise {
    return axios.get(
      `/api/bankid/status2?orderRef=${orderRef}&attempt=${attempt}&vetpanelAuth=1`
    );
  },

  authenticateAdmin(payload: any): AxiosPromise {
    return axios.post('/api/auth/login/panel', payload);
  },

  login(payload: any): AxiosPromise {
    return axios.post('/api/auth/vet-login', payload);
  },

  loginTwoFactor(payload: any): AxiosPromise {
    return axios.post('/api/auth/user/sms/code/start', payload, {
      headers: {
        Authorization: `Bearer ${payload.token}`,
        'Access-Control-Allow-Origin': '*',
      },
    });
  },
  submitCodeTwoFactor(code: string, token: string): AxiosPromise {
    return axios.post(
      '/api/auth/user/sms/code/check',
      { code },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Access-Control-Allow-Origin': '*',
        },
      }
    );
  },

  requestNewPassword(payload: any): AxiosPromise {
    return axios.post('/api/auth/request-new-password', payload);
  },

  resetPassword(payload: any): AxiosPromise {
    return axios.post('/api/auth/reset-password', payload);
  },
};
