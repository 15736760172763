import router from '@/router';
import store from '@/store';
import App from '@/App.vue';

/** Plugins */
import '@/plugins/axios';
import '@/plugins/casl';
import '@/plugins/fontawesome';
import '@/plugins/vselect';
import '@/plugins/lodash';
import '@/plugins/vee-validate';
import '@/plugins/vue-notification';
import '@/plugins/logrocket';
import '@/plugins/v-tooltip';
import '@/plugins/sentry';
import '@/plugins/echo';
import '@/plugins/composition-api';
import '@/plugins/gtm';
import { setupAnalytics } from '@/plugins/analytics/utils';

/** Styles */
import '@/assets/sass/style.scss';
import '@/assets/styles/tailwind.postcss';
import { i18n } from '@/i18n/i18n';
/** UI Library */
import '@/UI';

import Vue from 'vue';

// import { makeServer } from './server';

// if (process.env.NODE_ENV === 'development') {
//   makeServer();
// }

require('dotenv').config();

setupAnalytics();

Vue.config.productionTip = false;
// Vue.use(ClinicMap, { store, locale: 'uk' });

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app');
