import Vue from 'vue';
import axios from 'axios';
import { httpErrorHandler } from '@/utils/error-handler';
import { getDefaultApi } from '@/utils/http-utils';
import store from '@/store/index';
import { getTimeZoneName } from '@/utils/helpers/timezone-helpers';
import { formatInTimeZone } from 'date-fns-tz';

const AUTH_TOKEN = store.state.auth.token;
if (AUTH_TOKEN) {
  axios.defaults.headers.common.Authorization = `Bearer ${AUTH_TOKEN}`;
}
// eslint-disable-next-line prettier/prettier
axios.defaults.headers.common['Accept-Language'] =
  localStorage.getItem('locale');
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.common['Content-Type'] =
  'application/x-www-form-urlencoded';
axios.defaults.headers.common['Cache-Control'] = 'no-cache';

const config = {
  baseURL: getDefaultApi(),
  timeout: 60 * 1000, // Timeout
  withCredentials: false,
};

const axiosInstance = axios.create(config);

axiosInstance.interceptors.request.use(
  requestConfig => {
    const cfg = {
      ...requestConfig,
    };

    const timeZoneRegion = getTimeZoneName();

    cfg.headers.common['X-Date'] = formatInTimeZone(
      new Date(),
      timeZoneRegion,
      'yyyy-MM-dd HH:mm:ss z'
    );

    cfg.headers.common['X-Timezone'] = timeZoneRegion;

    return cfg;
  },
  error => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    return httpErrorHandler(error, !!AUTH_TOKEN);
  }
);

Plugin.install = vue => {
  // eslint-disable-next-line no-param-reassign
  vue.axios = axiosInstance;
  window.axios = axiosInstance;
  Object.defineProperties(vue.prototype, {
    axios: {
      get() {
        return axiosInstance;
      },
    },
    $axios: {
      get() {
        return axiosInstance;
      },
    },
  });
};

Vue.use(Plugin);

export default axiosInstance;
