<template>
  <div>
    <p v-if="errorMsg" class="text-center text-red-800">
      {{ errorMsg }}
    </p>
    <div v-if="legacyLogin">
      <div
        class="mt-4 flex w-full flex-wrap justify-between border-b border-gray-200 px-8"
      >
        <div class="inline-flex space-x-4">
          <button
            class="border-b-2 border-transparent px-3 py-2 hover:text-gray-900"
            :class="{ 'border-navy': loginMethod === 'bankId' }"
            @click="loginMethod = 'bankId'"
          >
            BankID
          </button>
          <button
            class="border-b-2 border-transparent px-3 py-2 hover:text-gray-900"
            data-testid="login-email"
            :class="{ 'border-navy': loginMethod === 'email' }"
            @click="loginMethod = 'email'"
          >
            Email
          </button>
        </div>
      </div>
      <email-password-login v-if="loginMethod === 'email'" />
      <form
        v-if="loginMethod === 'bankId'"
        id="bankIdLoginForm"
        class="flex flex-wrap px-8 py-8"
        @submit.prevent="submit"
      >
        <p
          v-if="message"
          class="mb-2 block"
          :class="{ 'text-red': uiState === 'error' }"
        >
          {{ message }}
        </p>
        <transition name="fade">
          <div
            v-if="uiState === 'idle' || uiState === 'error'"
            class="flex w-full flex-col space-y-2"
          >
            <base-label for="email"> Sign in with BankID </base-label>
            <div class="flex w-full flex-col space-y-4">
              <base-input
                id="ssn"
                v-model="ssn"
                v-focus
                type="tel"
                class="w-1/2"
                placeholder="Enter your SSN"
                required
              />
              <base-button color="primary" :disabled="!ssn">
                <font-awesome-icon icon="sign-in-alt" />
              </base-button>
            </div>
          </div>
          <div
            v-if="uiState === 'loading'"
            class="w-full rounded bg-azure-25 p-4 shadow-sm"
          >
            <div class="block">
              <div>
                <span>Open the BankID app in your mobile phone</span>
                <span class="ml-2"><base-spinner class="text-xl" /></span>
              </div>
              <base-button
                color="cancel"
                type="button"
                class="mt-4"
                @click="cancelAuth"
              >
                Cancel
              </base-button>
            </div>
          </div>
          <div
            v-if="uiState === 'authorized'"
            class="w-full rounded bg-primary-lighter p-4 shadow-sm"
          >
            <div class="flex items-center space-x-2">
              <fv-icon icon="check-mark" class="text-navy" />
              <span>Authorized! Redirecting..</span>
              <span class="ml-2"><base-spinner class="text-xl" /></span>
            </div>
          </div>
        </transition>
      </form>
    </div>
    <base-button
      v-else
      color="primary"
      class="my-4 w-full"
      :to="firstvetAuthUrl"
      :href="firstvetAuthUrl"
      data-testid="login-button"
    >
      Logga in
    </base-button>
  </div>
</template>

<script>
import AuthApi from '@/api/modules/auth';
import { mapActions, mapMutations } from 'vuex';
import { __DEV__, FIRSTVET_AUTH_URL, BASE_URL } from '@/constants';

import { defineComponent } from '@vue/composition-api';
import Echo from '@/plugins/echo';
import EmailPasswordLogin from './EmailPasswordLogin.vue';

export default defineComponent({
  components: { EmailPasswordLogin },
  props: {
    legacyLogin: {
      type: Boolean,
      default: false,
    },
    errorMsg: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loginMethod: 'bankId',
      ssn: '',
      uiState: 'idle',
      message: '',
      interval: null,
      attempts: 0,
      orderRef: null,
      autoStartToken: null,
      userData: null,
    };
  },
  computed: {
    firstvetAuthUrl() {
      return `${FIRSTVET_AUTH_URL}/se/?platform=vetpanel&redirect_url=${BASE_URL}/sv`;
    },
  },
  mounted() {
    this.setPlatform('sv');
    this.$axios.defaults.baseURL = process.env.VUE_APP_API_URL_SV;
    Echo.connector.pusher.config.authEndpoint = `${process.env.VUE_APP_API_URL_SV}/broadcasting/auth`;

    if (this.uiState === 'idle') {
      if (document.getElementById('ssn')) {
        document.getElementById('ssn').focus();
      }
      if (document.getElementById('email')) {
        document.getElementById('email').focus();
      }
    }
  },
  beforeDestroy() {
    clearInterval(this.interval);
    this.interval = null;
  },
  methods: {
    ...mapActions('auth', ['setAuthData', 'handleLoginRoutePush']),
    ...mapActions('user', ['fetchUser']),
    ...mapMutations('user', ['setUser']),
    ...mapMutations('auth', ['setRoles', 'setLocale']),
    ...mapActions(['setPlatform']),
    async submit() {
      this.uiState = 'loading';
      this.message = '';

      if (__DEV__) {
        const { data } = await AuthApi.initDevBankId({ ssn: this.ssn }).catch(
          e => {
            this.uiState = 'error';
            this.message = e.message;
          }
        );
        this.userData = {
          personalNumber: data.ssn,
        };
        this.handleAuth();
      } else {
        const { data } = await AuthApi.initBankId({ ssn: this.ssn }).catch(
          e => {
            this.uiState = 'error';
            this.message = e.message;
          }
        );
        if (!data.order_reference) {
          this.uiState = 'error';
          this.message = data;
        }
        if (data.order_reference[1] === 'ALREADY_IN_PROGRESS') {
          this.message =
            'A login is already in progress. Please wait a minute and try again.';
          this.uiState = 'error';
        } else {
          this.orderRef = data.order_reference[0].orderRef;
          this.autoStartToken = data.order_reference[0].autoStartToken;

          this.attempts = 0;
          this.interval = setInterval(this.reCheckBankID, 2000);
        }
      }
    },
    async reCheckBankID() {
      if (this.uiState === 'cancelled') {
        this.cancel();
      }
      this.attempts += 1;
      this.uiState = 'loading';
      const { data } = await AuthApi.checkBankIdResponse(
        this.orderRef,
        this.attempts
      ).catch(e => {
        this.uiState = 'error';
        this.message = e.message;
      });

      if (data.message === 'ok') {
        clearInterval(this.interval);
        if (data.order_ref_given[1] === 'EXPIRED_TRANSACTION') {
          this.uiState = 'cancelled';
          this.message = 'Time expired, please try again.';
          return;
        }
        if (data.order_ref_given[0]) {
          if (data.order_ref_given[0].progressStatus === 'COMPLETE') {
            this.userData = data.order_ref_given[0].userInfo;
            this.handleAuth();
          } else {
            this.uiState = 'cancelled';
            this.message = 'Something went wrong. Try again';
          }
        }
      }
    },
    async handleAuth() {
      this.uiState = 'authorized';

      const payload = {
        ...this.userData,
        os: 'browser',
      };
      const { data } = await AuthApi.authenticateAdmin(payload).catch(e => {
        this.uiState = 'error';
        this.message = e.message;
      });

      if (data.token && data.token) {
        const locale = 'sv';
        this.setLocale(locale);
        await this.setAuthData(data.token);
        localStorage.setItem('selectedCountry', '752');
        localStorage.setItem('locale', locale);
      }
      await this.fetchUser();

      this.handleLoginRoutePush();
    },
    async cancelAuth() {
      await AuthApi.cancelBankId(this.orderRef);
      this.cancel();
    },
    cancel() {
      this.uiState = 'idle';
      this.message = 'Sign in cancelled.';
      clearInterval(this.interval);
    },
  },
});
</script>
