import { Firebase } from '@/api';

export default {
  state: {
    remoteConfigs: {},
    loading: false,
  },

  getters: {
    getRemoteConfigs: state => state.remoteConfigs,
  },

  mutations: {
    setRemoteConfig: (state, { key, remoteConfig }) => {
      state.remoteConfigs[key] = remoteConfig;
    },
    setLoading: (state, payload) => {
      state.loading = payload;
    },
  },

  actions: {
    fetchRemoteConfig({ commit, rootState }, payload) {
      commit('setLoading', true);
      const { user } = rootState;
      const countryId = user.country?.id;
      const key = `${payload}_${countryId}`;
      return Firebase.remoteConfig
        .getValue(key)
        .then(remoteConfig => {
          commit('setLoading', false);
          commit('setRemoteConfig', { key: payload, remoteConfig });
          Promise.resolve(remoteConfig);
        })
        .catch(e => {
          commit('setLoading', false);
          Promise.reject(e);
        });
    },
  },
};
