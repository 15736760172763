var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    {
      attrs: { name: "fade", mode: "out-in" },
      on: {
        beforeLeave: _vm.beforeLeave,
        enter: _vm.enter,
        afterEnter: _vm.afterEnter,
      },
    },
    [
      _vm.show
        ? _c("div", { staticClass: "fixed inset-0 z-50 bg-smoke flex " }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "click-outside",
                    rawName: "v-click-outside",
                    value: _vm.close,
                    expression: "close",
                  },
                ],
                staticClass:
                  "relative p-4 bg-white w-full max-w-md min-h-half m-auto flex-col flex border rounded",
              },
              [
                _vm._t("default"),
                _vm._t("buttons", function () {
                  return [
                    _vm.showCloseButton
                      ? _c(
                          "base-button",
                          {
                            attrs: { color: "cancel" },
                            on: {
                              click: function ($event) {
                                return _vm.$emit("close")
                              },
                            },
                          },
                          [_vm._v(" Close ")]
                        )
                      : _vm._e(),
                  ]
                }),
              ],
              2
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }