import { AxiosPromise } from 'axios';
import axios from '@/plugins/axios';

export default {
  postData(payload: any): AxiosPromise {
    return axios.put('/api/animal/update', payload);
  },

  updateAnimal(id: number, payload: any): AxiosPromise {
    return axios.put(`/api/animal/${id}`, payload);
  },

  fetchAnimal(id: number, locale: string): AxiosPromise {
    return axios.get(`/api/animal/get/${id}`, { params: { locale } });
  },

  fetchHistory(payload: any, locale: string): AxiosPromise {
    return axios.get(`/api/animal/${payload}/history`, { params: { locale } });
  },

  fetchAnimalTypes(locale: any): AxiosPromise {
    return axios.get('/api/animal/data/types', { params: { locale } });
  },
};
