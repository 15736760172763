import { InsuranceCompanyApi } from '@/api';

export default {
  state: {
    companies: [],
    insuranceList: [],
  },

  getters: {
    getCompanies: state => state.companies,
    getInsuranceList: state => state.insuranceList,
  },

  mutations: {
    setCompanies: (state, payload) => {
      state.companies = payload;
    },
    setInsuranceList: (state, payload) => {
      state.insuranceList = payload;
    },
  },

  actions: {
    fetchCompanies({ commit }, payload) {
      InsuranceCompanyApi.getAll({ customerId: payload })
        .then(res => {
          commit('setCompanies', res.data.companies);
          return res;
        })
        .catch(e => {
          return Promise.reject(e);
        });
    },
    fetchCompaniesByCountryId({ commit }, payload) {
      InsuranceCompanyApi.getAllByCountryId(payload.countryId, payload.params)
        .then(res => {
          commit('setInsuranceList', res.data.data || res.data.insurance);
          return res;
        })
        .catch(e => {
          return Promise.reject(e);
        });
    },
  },
};
