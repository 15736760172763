import remoteConfig from './remote-config-store';

export default {
  modules: {
    remoteConfig: {
      namespaced: true,
      ...remoteConfig,
    },
  },
};
