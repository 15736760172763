<template>
  <div
    id="app"
    class="w-full h-screen max-h-screen bg-beigeLighter text-gray-900 overflow-auto font-sans"
  >
    <transition name="fade">
      <notification-primary />
    </transition>
    <transition name="fade">
      <notification-error />
    </transition>
    <transition name="fade">
      <notification-auth />
    </transition>
    <transition name="fade">
      <notification-booking />
    </transition>
    <router-view v-if="user || isGuestRoute" />
    <div
      v-else
      class="flex flex-col h-full w-full justify-center items-center relative bg-beigeLighter"
    >
      <div class="text-gray-700 mx-auto my-auto text-center flex flex-col space-y-4">
        <img
          :src="require('@/assets/svg/loading_relax.svg')"
          class=" w-56 h-64 mx-auto my-auto"
        >

        <p class="mt-4 font-semibold inline-flex space-x-2 mx-auto">
          <span class="text-lg font-display">Initializing..</span>
          <span>
            <base-spinner
              :loading="loading"
              size="lg"
            />
          </span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapState, mapGetters } from 'vuex';
import { defineComponent } from '@vue/composition-api';
import { __STAGING__ } from '@/constants';

export default defineComponent({
  name: 'Vetpanel',

  data() {
    return {
      isStaging: __STAGING__,
      loading: false,
    };
  },

  computed: {
    ...mapState(['showTimeline', 'showSupportTicket']),
    ...mapGetters('user', {
      user: 'getUser',
    }),
    isGuestRoute() {
      return this.$route.meta && this.$route.meta.guest;
    },
  },
  async mounted() {
    await this.fetchVersion();

    setInterval(() => {
      this.compareAppVersions();
    }, 60000 * 10);
  },

  methods: {
    ...mapMutations(['toggleSupportTicketModal']),
    ...mapActions(['compareAppVersions', 'fetchVersion']),
  },
});
</script>
