import { AxiosPromise } from 'axios';
import axios from '@/plugins/axios';

export default {
  fetchService(id, params): AxiosPromise {
    return axios.get(`/api/service/${id}`, { params });
  },
  fetchServices(params): AxiosPromise {
    return axios.get('/api/services', { params });
  },
  fetchSlots(params): AxiosPromise {
    return axios.get('/api/v2/slots', { params });
  },
  createAppointment(payload): AxiosPromise {
    return axios.post('/api/v2/appointments', payload);
  },
  reserveSlot(id, payload): AxiosPromise {
    return axios.post(`/api/v2/appointments/${id}/reservation`, payload);
  },
  updateAppointment(id, payload): AxiosPromise {
    return axios.put(`/api/v2/appointments/${id}`, payload);
  },
  confirmBooking(id, payload): AxiosPromise {
    return axios.post(`/api/v2/appointments/${id}/confirm`, payload);
  },
  deleteSlotReservation(id, payload): AxiosPromise {
    return axios.delete(`/api/v2/appointments/${id}/reservation`, payload);
  },
  fetchFreeMeetingCount(params): AxiosPromise {
    return axios.get(`api/my/free-appointments`, { params });
  },
  createOrUpdateBookingMetaData(id, payload): AxiosPromise {
    return axios.post(`api/v2/appointments/${id}/booking-metadata`, payload);
  },
  sendAppointmentConfirmationLink(appointmentId) {
    return axios.post(`api/v2/appointments/${appointmentId}/send-confirm-link`);
  },
  fetchUser(userId) {
    return axios.get(`api/v2/user/${userId}`);
  },
  createUserByAttributes(countryCode, mobilePhone, ssn, countryId) {
    return axios.post(`api/v2/user/by-attributes`, {
      countryCode,
      mobilePhone,
      ssn,
      countryId,
    });
  },
  addAnimalToUser(
    userId,
    animalName,
    animalTypeId,
    gender = null,
    birthdate = null
  ) {
    const payload = {
      userId,
      animalTypeId,
      name: animalName,
      gender,
      birthdate,
    };

    return axios.post(`api/v2/animals`, payload);
  },

  updateAnimal(animalId, payload) {
    return axios.put(`api/v2/animals/${animalId}`, payload);
  },

  fetchAnimal(animalId, userId) {
    return axios.get(`api/v2/animals/${animalId}`, {
      params: { withInsuranceCompany: 1, userId },
    });
  },
  getAppointmentShortLinkStatus(appointmentId) {
    return axios.get(
      `api/v2/appointments/${appointmentId}/confirm-link/status`
    );
  },
};
