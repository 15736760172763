import { AxiosPromise } from 'axios';
import axios from '@/plugins/axios';

export default {
  async fetchAppointmentQueue(params): Promise<string> {
    const { data } = await axios.get('api/v2/queues/appointments', { params });
    return data;
  },

  addRandomUsersToTestQueue(usersToAdd: number) {
    return axios.post('api/v2/queues/test-fill-queue', null, {
      params: { usersToAdd },
    });
  },

  updateQueueAssignee(appointmentId: number): AxiosPromise {
    return axios.put(
      `/api/v2/queues/appointments/${appointmentId}/assignee`,
      []
    );
  },

  deleteQueueAssignee(id: number, data: any): AxiosPromise {
    return axios.delete(`/api/v2/queues/appointments/${id}/assignee`, data);
  },

  async fetchQueueWaitingTimes(): Promise<string> {
    const { data } = await axios.get(
      '/api/v2/queues/appointments/waiting-times'
    );
    return data.data;
  },
};
