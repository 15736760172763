var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "w-full h-screen max-h-screen bg-beigeLighter text-gray-900 overflow-auto font-sans",
      attrs: { id: "app" },
    },
    [
      _c(
        "transition",
        { attrs: { name: "fade" } },
        [_c("notification-primary")],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "fade" } },
        [_c("notification-error")],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "fade" } },
        [_c("notification-auth")],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "fade" } },
        [_c("notification-booking")],
        1
      ),
      _vm.user || _vm.isGuestRoute
        ? _c("router-view")
        : _c(
            "div",
            {
              staticClass:
                "flex flex-col h-full w-full justify-center items-center relative bg-beigeLighter",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "text-gray-700 mx-auto my-auto text-center flex flex-col space-y-4",
                },
                [
                  _c("img", {
                    staticClass: " w-56 h-64 mx-auto my-auto",
                    attrs: { src: require("@/assets/svg/loading_relax.svg") },
                  }),
                  _c(
                    "p",
                    {
                      staticClass:
                        "mt-4 font-semibold inline-flex space-x-2 mx-auto",
                    },
                    [
                      _c("span", { staticClass: "text-lg font-display" }, [
                        _vm._v("Initializing.."),
                      ]),
                      _c(
                        "span",
                        [
                          _c("base-spinner", {
                            attrs: { loading: _vm.loading, size: "lg" },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              ),
            ]
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }