const ukTranslations = {
  select_for_referral: 'Select clinic',
  filter: 'Filter',
  active: 'Active',
  inactive: 'Inactive',
  value_missing: 'Value missing',
  field_cannot_be_empty: 'Field cannot be empty',
  fetch_address_info: 'Fetch location info',
  information_found: 'Information found',
  address: 'Address',
  latitude: 'Latitude',
  longitude: 'Longitude',
  is_information_correct: 'Does this look accurate?',
  save_coordinates: 'Save coordinates',
  save_and_move_cursor_sideways: 'Save and move cursor sideways',
  could_not_find_any_address_info:
    'Could not find any results. Please make sure the address is correct.',
  something_went_wrong: 'Something went wrong. Please try again later.',
  address_missing: 'Address is missing.',
  cancel: 'Cancel',
  type_of_animal: 'Type of animals',
  choose_type_of_animal: 'Choose type',
  clinic_accepts: 'The clinic accepts',
  add_animal_type: 'Add animal type',
  add: 'Add',
  contact_information: 'Contact details',
  name: 'Name',
  city: 'City',
  municipality: 'County',
  country: 'Country',
  email: 'Email',
  phone: 'Phone',
  website: 'Website',
  status: 'Status',
  '2288-pilot': '2228-pilot',
  size_general_info: 'Size and general information',
  clinic_type: 'Clinic type',
  number_of_vets: 'Number of vets',
  small_animals: 'Small animals',
  horse: 'Horse',
  general_info: 'General information',
  information_horse_service: 'Information horse service',
  referral: 'Referral',
  email_referral: 'Email for referral',
  phone_referral: 'Phone number for referral',
  referral_information: 'Referral information',
  location_info: 'Information about location',
  coordinates: 'Coordinates',
  services: 'Services',
  add_specific_service: 'Add/remove services',
  animal_group: 'Animal category',
  service_category: 'Service category',
  save_services: 'Save services',
  availability: 'Availability',
  emergency_care_options: 'Emergency care (treatment options)',
  emergency_care: 'Emergency care',
  on_call_options: 'On call service',
  inpatient_care_small_animals: 'Inpatient care (small animals)',
  inpatient_care_horses: 'Inpatient care (horses)',
  yes: 'Yes',
  no: 'No',
  opening_hours: 'Opening hours',
  opens: 'Opens',
  closes: 'Closes',
  closed: 'Closed',
  save_opening_hours: 'Save opening hours',
  opening_hours_saved: 'Opening hours saved',
  other_opening_hours: 'Other opening hours',
  other_opening_hours_placeholder: 'Ex. closed for lunch',
  opening_hours_error:
    'Something went wrong. Please make sure the closing hour is after the opening hour.',
  opening_hours_missing_error:
    'Both opening and closing hour must be filled (unless closed).',
  selected_time_is_in_wrong_format: 'Selected time is in wrong format',
  copy_contact_info: 'Copy contact info',
  copy_clinic_name: 'Copy clinic name',
  copied_to_clipboard: 'Copied to clipboard!',
  phone_number: 'Phone number',
  public: 'Public',
  referral_hidden: 'Referral (hidden)',
  inpatient_care: 'Inpatient care',
  general_information: 'General information',
  horses: 'Horses',
  horses_field: 'Horses (field)',
  animal_species: 'Animal species',
  agricultural_animals: 'Agricultural animals',
  referral_info: 'Referral info',
  search: 'Search',
  no_search_results: 'No search results',
  mon: 'Mon',
  tue: 'Tue',
  wed: 'Wed',
  thu: 'Thu',
  fri: 'Fri',
  sat: 'Sat',
  sun: 'Sun',
  monday: 'Monday',
  tuesday: 'Tuesday',
  wednesday: 'Wednesday',
  thursday: 'Thursday',
  friday: 'Friday',
  saturday: 'Saturday',
  sunday: 'Sunday',
  currently_open: 'Currently open',
  clinics: 'Clinics',
  active_areas: 'Active areas',
  distances: 'Distances',
  save: 'Save',
};

export default ukTranslations;
