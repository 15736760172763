










































































































import { computed, nextTick, ref, defineComponent } from '@vue/composition-api';

export default defineComponent({
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    value: {
      type: [String, Number],
      default: undefined,
    },
    valueAttr: {
      type: [String],
      default: 'id',
    },
    labelAttr: {
      type: [String],
      default: 'name',
    },
    placeholder: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: '50vh',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      validator(value: string) {
        return ['sm', 'md', 'lg'].includes(value);
      },
      default: 'md',
    },
    labelSize: {
      type: String,
      validator(value: string) {
        return ['sm', 'md', 'lg'].includes(value);
      },
      default: 'md',
    },
  },
  setup(props, { emit, refs }) {
    const baseClasses =
      'bg-white text-gray-800 rounded px-2 py-2 appearance-none';
    const borderClasses = 'border border-gray-400';
    const focusClasses = 'focus:outline-none focus:border-azure-25';
    const hoverClasses = 'hover:border-fv-gray-border-darker';

    const textSizes = {
      sm: 'text-sm',
      md: 'text-base',
      lg: 'text-lg',
    };

    const labelSizes = {
      sm: 'text-base md:text-sm',
      md: 'text-base',
      lg: 'text-base md:text-lg',
    };

    const searchStr = ref('');
    const isOpen = ref(false);

    const toggleOpen = () => {
      isOpen.value = !isOpen.value;
      if (isOpen.value) {
        nextTick(() => {
          const { searchInput } = refs;
          if (searchInput) searchInput.focus();
        });
      }
    };

    const selectedItem = computed(() => {
      return props.options.find(
        option => option[props.valueAttr] === props.value
      );
    });
    const filterOptions = computed(() => {
      if (searchStr.value) {
        return props.options.filter(option => {
          return option[props.labelAttr]
            .toLowerCase()
            .includes(searchStr.value.toLowerCase());
        });
      }
      return props.options;
    });

    const selectedLabel = computed(() => {
      if (props.value && props.options.length) {
        return props.options.find(
          option => option[props.valueAttr] === props.value
        )[props.labelAttr];
      }
      return props.placeholder;
    });

    const selectItem = (value: string | number) => {
      emit('change', value);
      isOpen.value = false;
      searchStr.value = '';
      nextTick(() => {
        refs.selectButton.focus();
      });
    };

    return {
      textSizes: textSizes[props.size],
      labelSizes: labelSizes[props.labelSize],
      baseClasses,
      focusClasses,
      hoverClasses,
      borderClasses,
      selectedItem,
      searchStr,
      filterOptions,
      selectItem,
      toggleOpen,
      selectedLabel,
      isOpen,
    };
  },
});
