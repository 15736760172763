<template>
  <div class="flex w-full flex-col overflow-auto">
    <div v-if="$slots.tableHeader" class="flex justify-between pb-4">
      <slot name="tableHeader" />
    </div>

    <div class="inline-block min-w-full py-2 align-middle">
      <div class="divide-y-2 divide-gray-400 border bg-gray-50 shadow">
        <div class="overflow-auto">
          <table class="relative min-w-full divide-y divide-gray-200">
            <thead class="">
              <tr>
                <template v-for="(thead, i) in theads">
                  <th
                    v-if="hideHeaderColumn(i)"
                    :key="i"
                    class="bg-gray-50 px-2 py-3 text-left text-sm font-semibold leading-4 text-gray-800 sm:px-6 lg:px-8"
                  >
                    {{ thead }}
                  </th>
                </template>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-200 bg-white">
              <div v-if="loading" class="flex flex-col justify-center py-12">
                <slot name="tableLoading" />
              </div>
              <slot />
            </tbody>
          </table>
        </div>
        <div v-if="$slots.tableFooter" class="flex py-2 px-2 sm:px-6 lg:px-8">
          <slot name="tableFooter" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    columnsToHide: {
      type: Array,
      default: () => [],
    },
    theads: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    hideHeaderColumn(columnIndex) {
      //console.log(this.columnsToHide);
      return !this.columnsToHide.includes(columnIndex);
    },
  },
};
</script>

<style lang="scss" scoped></style>
